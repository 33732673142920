import { Grid, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { NoticeRunner, Partnership } from 'components';
import { Chess } from 'components/icons';
import { gamesService } from 'services';
import { ListTopWithdraw, SliceBanner } from './components';

const Home = () => {
  const { data: home } = useQuery(['gamesService.openBanner'], () => gamesService.openBanner());

  return (
    <div>
      <div className='-container'>
        <SliceBanner />
        <NoticeRunner />
      </div>

      <div className='my-6'>
        <Paper className='h-[40px] flex items-center justify-between px-3 border-l-2 border-primary-dark'>
          <div>Giới thiệu</div>
          <Chess className='text-transparent' />
        </Paper>
        <Paper className='mt-2 px-3 py-2'>
          Công Ty TECHNO Investment chuyên hoạt động bên lĩnh vực đầu tư quảng bá thương hiệu , chuyển giao công nghệ số
          cho các doanh nghiệp lớn trong và ngoài nước. Thông qua các hoạt động thu hút Invest, xây dựng hệ sinh thái
          công nghệ số toàn cầu. TECHNO Investment luôn là đơn vị tiên phong đi đầu trong xu hướng chuyển đổi số. Uy tín
          - Tận tâm - Luôn luôn lắng nghe và thấu hiểu khách hàng là tiêu chí hàng đầu của chúng tôi. Được phục vụ khách
          hàng là niềm vinh dự của TECHNO Company.
        </Paper>
      </div>

      <div className='my-6'>
        <Paper className='h-[40px] flex items-center px-3 border-l-2 border-primary-dark'>
          <div>Thống kê</div>
        </Paper>
        <Paper className='mt-2 px-3 py-3'>
          <Grid container spacing={1.5}>
            {[
              { label: 'Số lượng đối tác', value: home?.numberOfSupplier },
              { label: 'Thành viên', value: home?.numberOfMember },
              { label: 'Cộng tác viên', value: home?.numberOfCollaborators },
              { label: 'Khách hàng tiềm năng', value: home?.potentialCustomers },
            ].map((item, index) => (
              <Grid item key={index} xs={6}>
                <div>
                  <div className='text-lg font-bold'>{item.value ?? 0}</div>
                  <div className='text-sm max-line-1 text-primary-light'>{item.label}</div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </div>

      <div className='hidden my-6'>
        <ListTopWithdraw />
      </div>
      <div className='my-6'>
        <Partnership isHome />
      </div>
    </div>
  );
};

export default Home;
